import React, { memo } from 'react';
import { formatDistance, isValid } from 'date-fns';
import {
  Box,
  Typography,
  Grid,
  CardContent,
  Card,
  CardActions,
  List,
  Paper,
  Button,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/system';
import config from '@configFile';
import { useGetUserData } from '@hooks/useGetUserData';
import IsCouponUsed from '@components/IsCouponUsed';
import MonetizingLink from '@components/MonetizingLink';
import { getCouponLinkFromCode, getCouponOffMessage } from '@utils/index';
import AdminCouponActions from '@components/AdminActions/AdminCouponActions';
import { DealPostType } from '@types';
import CouponListItem from '../CouponListItem';
import CouponImage from '../CouponImage';
import {
  getUniqueBrandsFromDeals,
  getLowestFinalPriceFromDeals,
  getCouponText,
  getSuggestedSubject,
  getIfhasUsedCouponAlready,
  getDefaultEmailText
} from './utils';

export interface PromoCodeGroupType {
  promoCode: string;
  latestDeals: DealPostType[];
  numPromoCodeDeals: number;
  highestDiscount: number;
  lowestFinalPrice: number;
  couponFixed: any;
  couponPercentage: any;
  image: string;
  imageHeight: number;
  imageWidth: number;
  earliestDateActive?: Date;
}

interface CouponListProps {
  promoCodeGroup: PromoCodeGroupType;
  max?: number;
  tag?: string;
  openExpanded?: boolean;
}

const CouponList = ({
  promoCodeGroup,
  tag = config.AFFILIATE_TAGS.COUPON_PAGE
}: CouponListProps) => {
  const { data: user } = useGetUserData();
  const isAdmin = !!user?.isAdmin;
  const theme = useTheme();
  const isExtraSmall = useMediaQuery('(max-width:310px)');
  const isDarkMode = theme.palette.mode === 'dark';
  const {
    promoCode,
    couponFixed,
    couponPercentage,
    latestDeals,
    numPromoCodeDeals,
    image,
    imageWidth,
    imageHeight,
    earliestDateActive
  } = promoCodeGroup;

  const Container = Card;

  const cardProps = {
    elevation: 4,
    component: Paper,
    sx: {
      marginBottom: '12px',
      border: `2px dashed ${isDarkMode ? '#bda4f4' : '#392e50'}`, // Dashed border for coupon effect
      borderRadius: '8px', // Rounded corners
      // #424242
      background: isDarkMode ? 'transparent' : 'white',
      padding: '4px',
      position: 'relative',
      overflow: 'hidden',
      // less than 540 px width
      // '@media (max-width: 540px)': {
      //   padding: '4px'
      // },
      boxShadow: 3

      // '&:before': {
      //   content: '"COUPON"', // Text inside the ribbon
      //   position: 'absolute',
      //   top: '10px',
      //   right: '-30px',
      //   width: '100px',
      //   height: '20px',
      //   background: '#ff5722', // Ribbon color
      //   color: '#fff', // Text color
      //   textAlign: 'center',
      //   lineHeight: '20px',
      //   transform: 'rotate(45deg)', // Rotate the ribbon
      //   fontSize: '12px',
      //   fontWeight: 'bold',
      //   zIndex: 1,
      //   boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' // Add a subtle shadow
      // }
    }
  };

  const hasUsedCouponAlready = getIfhasUsedCouponAlready(user, promoCode);
  const allUniqueBrands = getUniqueBrandsFromDeals(latestDeals);
  const lowestFinalPrice = getLowestFinalPriceFromDeals(latestDeals);
  const msgCouponAmount = getCouponOffMessage(couponFixed, couponPercentage);
  const couponMsg = getCouponText(
    allUniqueBrands,
    numPromoCodeDeals,
    msgCouponAmount
  );
  const defaultEmailText = getDefaultEmailText(
    allUniqueBrands,
    numPromoCodeDeals,
    msgCouponAmount
  );

  const suggestedSubject = getSuggestedSubject(
    latestDeals,
    couponMsg,
    lowestFinalPrice
  );

  return (
    <Container {...cardProps}>
      <CardContent
        sx={{
          padding: '1rem !important'
        }}
      >
        <CardActions
          sx={{
            padding: '0px',
            justifyContent: 'space-between'
          }}
          disableSpacing
        >
          <Box
            sx={{
              width: '100%'
            }}
          >
            {image ? (
              <CouponImage
                image={image}
                imageHeight={imageHeight}
                imageWidth={imageWidth}
                promoCode={promoCode}
                tag={tag}
              />
            ) : null}
            <Grid container>
              <Grid item xs={12} sm={7} md={6} lg={8}>
                <MonetizingLink
                  href={getCouponLinkFromCode(promoCode, tag)}
                  target="_blank"
                  sx={{
                    color: theme.palette.linkColor,
                    fontWeight: 700
                  }}
                  tag={tag}
                  clickType="amazon-coupon-page-active-link"
                >
                  <Typography
                    component="span"
                    variant="h6"
                    sx={{
                      fontWeight: 700,
                      color: isDarkMode ? '#bda4f4' : '#392e50', // Red color for discount message
                      fontSize: {
                        xs: '1.25rem',
                        sm: '1.5rem'
                      }
                    }}
                  >
                    {couponMsg}
                  </Typography>
                </MonetizingLink>
                {earliestDateActive && isValid(earliestDateActive) ? (
                  <Box>
                    <Typography variant="caption">
                      {`Amazon coupon first added ${formatDistance(
                        earliestDateActive,
                        new Date(),
                        {
                          addSuffix: true
                        }
                      )}`}
                    </Typography>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={5} md={6} lg={4}>
                <Box
                  sx={{
                    display: {
                      xs: isAdmin ? 'flex' : 'block',
                      sm: 'flex'
                    }
                  }}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {isAdmin ? (
                    <Box display="inline" marginRight="8px">
                      <AdminCouponActions
                        suggestedSubject={suggestedSubject}
                        promoCode={promoCode}
                        brands={allUniqueBrands}
                        defaultEmailText={defaultEmailText}
                      />
                    </Box>
                  ) : null}
                  <IsCouponUsed
                    promoCode={promoCode}
                    tag={tag}
                    ASIN={latestDeals?.[0]?.ASIN || ''}
                    showCouponMessage={false}
                    shouldClaimedCoupon={false}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CardActions>
        <Box
          sx={{
            display: 'inline',
            opacity: hasUsedCouponAlready ? '0.4' : '1'
          }}
        >
          <List>
            {latestDeals.map((latestDeal) => {
              return (
                <CouponListItem
                  key={latestDeal.ASIN}
                  latestDeal={latestDeal}
                  tag={tag}
                />
              );
            })}
          </List>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '8px'
          }}
        >
          <Button
            variant="contained"
            href={getCouponLinkFromCode(promoCode, tag)}
            target="_blank"
            sx={{
              border: `1px solid ${isDarkMode ? '#bda4f4' : '#392e50'}`
            }}
            size={isExtraSmall ? 'small' : 'medium'}
          >
            Redeem Coupon
          </Button>
        </Box>
      </CardContent>
    </Container>
  );
};

export default memo(CouponList);
