import cloneDeep from 'lodash/cloneDeep';

import {
  List,
  ListItem,
  MakeListsState,
  ListAction,
  LISTS_UPDATE_LIST,
  LIST_VIEW_LOADED
} from '../types';

export const emptyList: List = {
  title: '',
  isLive: false,
  tagline: '',
  slug: '',
  image: '',
  description: '',
  publishedAt: null,
  listItems: []
};

export const emptyListItem: ListItem = {
  id: null,
  title: '',
  store: 'AMAZON',
  description: '',
  ASIN: null,
  image: '',
  imageWebp: null,
  brand: null
};

// Export for unit testing
const initialState: MakeListsState = {
  lists: [],
  currentMode: 'list',
  currentList: null,
  currentId: null,
  currentSlug: null
};

export default (state = initialState, action: ListAction) => {
  let newState = null;
  switch (action.type) {
    case LIST_VIEW_LOADED:
      newState = cloneDeep(state);
      newState.currentId = action.list.id;
      newState.currentList = action.list;
      newState.currentSlug = action.list.slug;
      return newState;
    case LISTS_UPDATE_LIST:
      newState = cloneDeep(state);
      newState.currentList = action.data;
      return newState;
    default:
      return state;
  }
};
