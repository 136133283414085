import React, { useState, useRef } from 'react';
import debounce from 'lodash/debounce';
import times from 'lodash/times';
import {
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Pagination,
  useMediaQuery
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from '@mui/system';
import useLocalStorage from '@hooks/useLocalStorage';
import config from '@configFile';
import { useGetLatestDeals } from '@hooks/useDeals';
import { useGetCouponDetails } from '@hooks/useCoupons';
import { useGetUserData } from '@hooks/useGetUserData';
import trackUse from '@utils/trackUse';
import { logPostHogEvent } from '@utils/index';
import CouponCardSkeleton from './components/CouponCardSkeleton';
import CouponList from './components/CouponList';
import {
  getAllDealsToRender,
  getLatestDealsWithPromoCodeGroupedOrdered,
  getLatestDealsByPromoCodeGrouped
} from './utils';

interface CouponListsProps {
  max?: number;
  singleCode?: string;
  tag?: string;
  openExpanded?: boolean;
  showNoCouponFoundMessage?: boolean;
}

const CouponLists = ({
  max = 5,
  singleCode,
  tag = config.AFFILIATE_TAGS.COUPON_PAGE,
  openExpanded = true,
  showNoCouponFoundMessage = false
}: CouponListsProps) => {
  const theme = useTheme();
  const topRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilterDebounced, setSearchFilterDebounced] = useState('');
  const [isFiltering, setIsFiltering] = useState(false);

  // Removed the old "limit" logic in favor of page-based pagination
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useLocalStorage('coupon-limit', 30);

  const { data: dealsData, isLoading } = useGetLatestDeals();
  const { data: couponDetails, isLoading: isLoadingCouponDetails } =
    useGetCouponDetails();
  const { data: user } = useGetUserData();

  const latestDeals = dealsData?.latestDeals;

  const [orderBy, setOrderBy] = useState('dateActive');

  const isDarkMode = theme.palette.mode === 'dark';
  const backgroundColor = isDarkMode ? '#212121' : '#dcdbdf';

  const debounceUpdateFilteredItems = React.useCallback(
    debounce((value: string) => {
      setSearchFilterDebounced(value);
      setIsFiltering(false);

      trackUse({
        item: 'coupon-list-search',
        value,
        type: 'ACTION'
      });

      logPostHogEvent('coupon-list-search', {
        value,
        type: 'ACTION'
      });
    }, 1000),
    []
  );

  const onSearchInputChange = (value: string) => {
    setSearchTerm(value);
    if (value !== '') {
      setIsFiltering(true);
      debounceUpdateFilteredItems(value);
    } else {
      setIsFiltering(false);
      setSearchFilterDebounced('');
    }
    // Reset to page 1 whenever we change the search
    setPage(1);
  };

  // Filter deals down
  const latestDealsWithPromoCode = (latestDeals || []).filter(
    (latestDeal) => latestDeal.promoCode
  );

  // Group and order
  const latestDealsWithPromoCodeGrouped = getLatestDealsByPromoCodeGrouped(
    latestDealsWithPromoCode
  );
  const latestDealsWithPromoCodeGroupedOrdered =
    getLatestDealsWithPromoCodeGroupedOrdered({
      latestDealsWithPromoCodeGrouped,
      orderBy,
      singleCode,
      couponDetails,
      user
    });

  // Final, filtered list
  const allDealsToRender = getAllDealsToRender(
    latestDealsWithPromoCodeGroupedOrdered,
    searchFilterDebounced
  );

  // Single-code scenario
  if (singleCode) {
    const codeDeals = latestDealsWithPromoCodeGroupedOrdered.find(
      (promoCodeGroup) => promoCodeGroup.promoCode === singleCode
    );

    if (isLoading || isLoadingCouponDetails) {
      return (
        <>
          {times(1, (i) => (
            <CouponCardSkeleton key={i} />
          ))}
        </>
      );
    }

    if (!codeDeals) {
      if (showNoCouponFoundMessage) {
        return (
          <Box
            display="flex"
            justifyContent="center"
            textAlign="center"
            margin="16px"
          >
            <Typography variant="subtitle1">
              No deals found for this coupon code - what you were looking at may
              have expired! Close this modal to see the latest deals.
            </Typography>
          </Box>
        );
      }
      return null;
    }

    return (
      <CouponList
        promoCodeGroup={codeDeals}
        max={max}
        key={codeDeals.promoCode}
        tag={tag}
        openExpanded={openExpanded}
      />
    );
  }

  // Pagination logic
  const totalItems = allDealsToRender.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Indices for page slicing
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dealsOnPage = allDealsToRender.slice(startIndex, endIndex);

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderOrderCouponListSelect = () => (
    <Box
      sx={{
        display: 'inline',
        marginBottom: '0px',
        ml: {
          xs: 0,
          sm: 1
        }
      }}
    >
      <FormControl size="small">
        <InputLabel
          id="sort-small-label"
          sx={{
            backgroundColor: theme.palette.background.paper,
            padding: '0 4px'
          }}
        >
          Sort By
        </InputLabel>
        <Select
          labelId="sort-small-label"
          value={orderBy}
          onChange={(e) => {
            setOrderBy(e.target.value);
            trackUse({
              item: 'coupon-list-order-by',
              value: e.target.value,
              type: 'ACTION'
            });
            logPostHogEvent('coupon-list-order-by', {
              value: e.target.value,
              type: 'ACTION'
            });
            // Reset to page 1 whenever we reorder
            setPage(1);
          }}
          size="small"
        >
          <MenuItem dense value="dateActive">
            Newest Coupon Deals
          </MenuItem>
          <MenuItem dense value="highestDiscount">
            Highest Discount
          </MenuItem>
          <MenuItem dense value="numPromoCodeDeals">
            Number of Deals
          </MenuItem>
          <MenuItem dense value="cheapestdeals">
            Lowest Price Deals
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );

  const renderItemsPerPageSelect = () => (
    <Box
      sx={{
        display: 'inline',
        marginBottom: '0px',
        marginLeft: 1,
        marginTop: 0,
        '@media (max-width: 320px)': {
          marginLeft: 0,
          display: 'block',
          mt: 2
        }
      }}
    >
      <FormControl size="small">
        <InputLabel
          id="items-per-page-label"
          sx={{
            backgroundColor: theme.palette.background.paper,
            padding: '0 4px'
          }}
        >
          Items/Page
        </InputLabel>
        <Select
          labelId="items-per-page-label"
          value={itemsPerPage}
          label="Items/Page"
          onChange={(e) => {
            setItemsPerPage(e.target.value as number);
            setPage(1); // reset to page 1 if items/page changes
          }}
          sx={{
            minWidth: '90px'
          }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );

  const renderCouponSearch = () => (
    <Box
      sx={{
        display: {
          xs: 'block',
          sm: 'inline'
        },
        marginBottom: {
          xs: '12px',
          sm: 0
        },
        textAlign: 'end'
      }}
    >
      <TextField
        size="small"
        label="Search Coupons"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => onSearchInputChange(e.target.value)}
        InputProps={{
          endAdornment: (
            <Box sx={{ marginRight: '8px' }}>
              {!isFiltering && searchTerm !== '' && (
                <IconButton
                  size="small"
                  onClick={() => onSearchInputChange('')}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
          )
        }}
      />
    </Box>
  );

  const renderSkeletons = (numSkeletons: number) => (
    <>
      {times(numSkeletons, (i) => (
        <CouponCardSkeleton key={i} />
      ))}
    </>
  );

  return (
    <>
      <Box sx={{ display: 'block' }}>
        <Box
          ref={topRef}
          sx={{
            display: {
              xs: 'block',
              sm: 'flex'
            },
            maxWidth: '100%',
            mb: 1,
            mt: 2,
            justifyContent: 'flex-end'
          }}
        >
          {renderCouponSearch()}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              marginBottom: 0,
              '@media (max-width: 320px)': {
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'flex-end'
              }
            }}
          >
            {renderOrderCouponListSelect()}
            {renderItemsPerPageSelect()}
          </Box>
        </Box>
      </Box>

      <Box
        data-testid="coupon-content"
        width="100%"
        maxWidth="100%"
        margin="0 auto"
      >
        <Box
          sx={{
            backgroundColor,
            margin: '0 -17px',
            padding: '12px 12px'
          }}
        >
          {isLoading || isLoadingCouponDetails
            ? renderSkeletons(itemsPerPage)
            : dealsOnPage.map((promoCodeGroup) => (
                <CouponList
                  promoCodeGroup={promoCodeGroup}
                  max={max}
                  key={promoCodeGroup.promoCode}
                />
              ))}

          {/* Pagination Controls */}
          {!isLoading && totalPages > 1 && (
            <Box display="flex" justifyContent="center" mt={3}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                siblingCount={isMobile ? 0 : 1}
                boundaryCount={isMobile ? 1 : 2}
                size={isMobile ? 'small' : 'large'}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CouponLists;
